<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar title="销售设置" />
    </template>
    <div>
      <van-cell-group>
        <van-cell title="收款方式管理" @click="goToPayment" is-link />
        <van-cell title="销售预设折扣设置" @click="goToAlert" value="5种折扣" is-link />
        <van-cell title="积分抵现设置" @click="goToAlert" value="未设置" is-link />
        <van-cell title="销售抹零设置" @click="goToAlert" value="抹到分" is-link />
        <van-cell title="流水单号设置" @click="goToAlert" value="自动流水单号" is-link />
      </van-cell-group>
    </div>
    <template v-slot:routerView>
      <router-view />
    </template>
  </page-view>
</template>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import {
  Cell,
  CellGroup,
  Dialog
} from 'vant'
export default {
  name: 'SalesSetting',
  data () {
    return {}
  },
  components: {
    PageView,
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Dialog.name]: Dialog
  },
  created () {
  },
  mounted () {
    this.$store.dispatch('setting/loadSettings')
  },
  beforeDestroy () {
  },
  methods: {
    goToPayment () {
      this.$router.push('/setting/sales/epayment')
    },
    goToAlert () {
      Dialog({ title: '操作提示', message: '请登录生意专家电脑端使用完整版' })
    }
  },
  computed: {},
  watch: {}
}
</script>
